/*
 * @Author: guowu
 * @Date: 2021-11-15 13:43:50
 * @LastEditTime: 2021-12-15 17:17:16
 */
import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from "../index";
import axios from 'axios';

export const getOverviewNum = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/delear/overview", params);
};

export const getSearchResult = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/dealer/criteriaquery", params);
};

export const exportErrMsg = (params = {}): Promise<any> =>{
  return axios.post(
    "/rvapi/vl/dealer/err-msg-export",
    params,
    { responseType: "blob" }
  );
}

// 单张上传
export const licenseUpload = (
  licenseConfig = {},
  vinNo = "",
  dealerCode = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/vl/handlefile/license/upload?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params,
    licenseConfig
  );
};

// 单张OCR识别
export const licenseSubmit = (vinNo = "", params = {}): Promise<any> => {
  return fetchPostPic(
    "/rvapi/vl/vl/handlefile/license/upload?vinNo=" + vinNo,
    params
  );
};

// 单张取消上传
export const cancelUpload = (vinNo = "", dealerCode: string): Promise<any> => {
  return fetchGet(
    `/rvapi/vl/handlefile/cancelSingleUpload?vinNo=${vinNo}&dealerCode=${dealerCode}`
  );
};

// 单张提交结果
export const licenseResult = (vinNo = "", dealerCode: string): Promise<any> => {
  return fetchGetMess(
    `/rvapi/vl/singleSubmitResult?vinNo=${vinNo}&dealerCode=${dealerCode}`
  );
};

// 单张取消上传
export const cancelSingleUpload = (
  vinNo = "",
  dealerCode: string
): Promise<any> => {
  return fetchGet("/rvapi/basic/certificate/cancel/single/upload", {
    params: {
      dealerCode,
      vinNo,
    },
  });
};

// 获取文件大小
export const getfileSize = (vinNo = "", dealerCode: string): Promise<any> => {
  return fetchGet(
    `/rvapi/vl/handlefile/fileSize?vinNo=${vinNo}&dealerCode=${dealerCode}`
  );
};

//批量行驶证上传
export const batchLicenseFile = (
  licenseConfig = {},
  batchNo = "",
  dealerCode = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/vl/handlefile/batchLicense/upload?batchNo=${batchNo}&dealerCode=${dealerCode}`,
    params,
    licenseConfig
  );
};

//获取提交结果
export const batchSubmitResult = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/batchSubmitResult", params);
};

//获取提交进度
export const batchFileSubmit = (params = {}): Promise<any> => {
  return fetchGetMess("/rvapi/vl/handlefile/progress", params);
};

//取消批量上传
export const cancelUploadFileMulti = (batchNo = ""): Promise<any> => {
  return fetchGetMess("/rvapi/vl/handlefile/cancelUpload?batchNo=" + batchNo);
};

//获取基本信息
export const getBasicDetail = (params: {}): Promise<any> => {
  return fetchGet("./rvapi/vl/dealer/retailInfo", params);
};

//修改基本信息
export const sendEditInfo = (params: {}): Promise<any> => {
  return fetchPost("./rvapi/vl/dealer/modifiyRetailInfo", params);
};

//行驶证查看
export const getLicenseView = (params: {}): Promise<any> => {
  return fetchGet("./rvapi/vl/licenseView", params);
};

//详细 操作记录
export const getOperRecordDetail = (params: {}): Promise<any> => {
  return fetchGet("./rvapi/vl/dealer/operatorLog", params);
};

//详细 修改记录
export const getUpateRecordDetail = (params: {}): Promise<any> => {
  return fetchGet("./rvapi/vl/dealer/modifiyRecord", params);
};

//单车提交
export const singleLicenseSumbit = (
  vinNo: "",
  dealerCode = "",
  forceSubmit = false
): Promise<any> => {
  return fetchPostPic(
    `./rvapi/vl/dealer/singleVechileSubmit?vinNo=${vinNo}&dealerCode=${dealerCode}&forceSubmit=${forceSubmit}`
  );
};

//单车撤回
export const singleLicenseCallback = (
  vinNo: "",
  dealerCode = ""
): Promise<any> => {
  return fetchPostPic(
    `./rvapi/vl/dealer/vechileCallBack?vinNo=${vinNo}&dealerCode=${dealerCode}`
  );
};

//批量提交
export const batchVLSubmit = (params: {}): Promise<any> => {
  return fetchPostPic("./rvapi/vl/dealer/batchVechileSubmit", params, {
    headers: {
      "code-handler": "ignore",
    },
  });
};

//删除
export const basicDelete = (params: {}): Promise<any> => {
  return fetchPost("/rvapi/basic/certificate/license/delete", params);
};

export const getBatchSubmitResultTotal = (params: {}): Promise<any> => {
  return fetchGet("/rvapi/vl/batchSubmitResultTotal", { params });
};
